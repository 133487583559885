import React from "react";
import { useAuth0, } from "@auth0/auth0-react";
import { IonButton } from "@ionic/react";

const LoginButton = () => {
    const { user, isAuthenticated, loginWithRedirect,logout } = useAuth0()
    console.log(isAuthenticated)
    console.log(user);
    return !isAuthenticated?<IonButton onClick={() => loginWithRedirect()}>Log in</IonButton>:<IonButton onClick={()=>logout()}>Log out</IonButton>
};

export default LoginButton;