import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonPage,
	IonTitle,
	IonToolbar,
	IonGrid,
	IonRow,
	IonCol,
	IonAlert,
} from "@ionic/react";
import {
	arrowBackOutline,
	homeOutline,
	informationCircleOutline,
	trash,
} from "ionicons/icons";
import { useState } from "react";
import { PHOTO_STORAGE, UserPhoto } from "../common/types";
import { usePhotoGallery } from "../hooks/usePhotoGallery";
import { Storage } from "@capacitor/storage";
import "../theme/Utilities.css";

const MeasurementsPage: React.FC = () => {
	const { photos, setPhotos } = usePhotoGallery();
	const [ measurementToDelete, setMeasurementToDelete ] = useState<UserPhoto | null>(null);

	const removeMeasurement = async (id: string) => {
		const newPhotos = photos.filter(e => e.generatedId != id);
		Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) }).then(() => {
			console.log("SUCCESS")
			setPhotos(newPhotos as UserPhoto[]);
		}).catch(async res => {
			console.log(res);
		});
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/">
							<IonIcon slot="icon-only" icon={arrowBackOutline} />
						</IonBackButton>
					</IonButtons>

					<IonButtons>
						<IonTitle className="infoBtn">
							<IonButton routerLink="/InstructionsOnSwipe">
								<IonIcon slot="icon-only" icon={informationCircleOutline} />
							</IonButton>
						</IonTitle>
					</IonButtons>

					<IonButtons slot="end">
						<IonButton routerLink="/home" class="homeBtn">
							<IonIcon slot="icon-only" icon={homeOutline}></IonIcon>
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonToolbar>
					<IonTitle className="titleToolbar">My Measurements</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonGrid>{photos.map((photo, index) => {
					return (<IonRow>
						<IonCol><div key={index}>
							<IonButton
								className="measurement"
								routerLink={`/task/${photo.generatedId}/results`}
								expand="block"
								size="large"
								shape="round"
								color="light"
							>
								{!photo.name ? (<></>) : (<>{photo.name}</>)} <br className="row-separation" /> {photo.createdAt}
							</IonButton>
						</div></IonCol>
						<IonCol size="auto">
							<IonButton
								className="measurement"
								onClick={() => { setMeasurementToDelete(photo); } }
								expand="block"
								size="large"
								shape="round"
								color="light"
							>
								<IonIcon icon={trash}></IonIcon>
							</IonButton>
						</IonCol>
					</IonRow>);
				})}</IonGrid>
				<IonAlert
					header="Confirm delete"
					message={`Do you want to delete the measurement named "${measurementToDelete?.name}"?`}
					isOpen={measurementToDelete != null}
					buttons={[
					{
						text: 'Cancel',
						role: 'cancel',
						handler: () => { },
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => { removeMeasurement(measurementToDelete?.generatedId || ''); },
					},
					]}
					onDidDismiss={({ detail }) => { setMeasurementToDelete(null); }}
				></IonAlert>
			</IonContent>
		</IonPage>
	);
};

export default MeasurementsPage;
