import { IonAlert, IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonModal, IonPage, IonRow, IonSpinner, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useState } from "react";
import ThreeDModel from "../components/3DModel";
import "../theme/Results.css";
import { PIPEDB_URL } from '../common/pipedb-url';

import heightIcon from "../assets/Icons-Images-Video/heightIcon.png";
import headIcon from "../assets/Icons-Images-Video/headIcon.png";
import neckIcon from "../assets/Icons-Images-Video/neckIcon.png";
import chestIcon from "../assets/Icons-Images-Video/chestIcon.png";
import waistIcon from "../assets/Icons-Images-Video/waistIcon.png";
import buttocksIcon from "../assets/Icons-Images-Video/buttocksIcon.png";
import innerLegIcon from "../assets/Icons-Images-Video/innerLegIcon.png";
import outerArmIcon from "../assets/Icons-Images-Video/outerArmIcon.png";
import "../theme/Utilities.css";
import { useHistory, useParams } from "react-router";
import { MeasurementResource, ObjectModel, PHOTO_STORAGE, UserPhoto } from "../common/types";
import { usePhotoGallery } from "../hooks/usePhotoGallery";
import { Storage } from "@capacitor/storage";
import Measurements from "../components/Measurements";
import NameModal from "../components/NameModal";

const ResultPage: React.FC = () => {
	const history = useHistory();
	const { photos, setPhotos } = usePhotoGallery()
	const { id } = useParams<{ id: string }>()
	const [isFetched, setIsFetched] = useState(false);
	const [objectModel, setObjectModel] = useState<ObjectModel>({
		id: "",
		createdAt: "",
		obj: "",
		visibleEffects: [0],
		measurements: {
			id: "",
			createdAt: "",
			height: 0,
			head: 0,
			neck: 0,
			chest: 0,
			waist: 0,
			buttocks: 0,
			leg_inner: 0,
			arm_outer: 0
		}
	});
	const [measurements, setMeasurements] = useState<MeasurementResource>({
		id: "",
		createdAt: "",
		height: 0,
		head: 0,
		neck: 0,
		chest: 0,
		waist: 0,
		buttocks: 0,
		leg_inner: 0,
		arm_outer: 0,
	});
	const [localStorageIsFull, setLocalStorageIsFull] = useState(false)
	const [isOpen, setIsOpen] = useState(false);



	function showHideVisualHeight() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [1] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 1) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});
		}
	}

	function showHideVisualHead() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [2] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 2) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});
		}
	}

	function showHideVisualNeck() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [3] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 3) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});
		}
	}

	function showHideVisualChest() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [4] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 4) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});
		}
	}

	function showHideVisualArms() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [5] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 5) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});
		}
	}

	function showHideVisualWaist() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [6] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 6) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});
		}
	}

	function showHideVisualButtocks() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [7] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 7) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});
		}
	}

	function showHideVisualLegs() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [8] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 8) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});
		}
	}

	const fetchModel = async () => {
		await fetch(
			`${PIPEDB_URL}/task/${window.location.href.split("/")[4]
			}/model`
		)
			.then((res) => {
				if (!res.ok) {
					throw new Error(res.statusText);
				}
				return res.json();
			})
			.then((data) => {
				console.log(data);
				setObjectModel(data);
				setIsFetched(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const fetchMeasurements = async () => {
		
		const heightInput = await fetch(`${PIPEDB_URL}/task/${window.location.href.split("/")[4]}/height_input`, {
			method: 'GET'
		}).then(res => res.json()).then(data => data.value);
		await fetch(
			`${PIPEDB_URL}/task/${window.location.href.split("/")[4]
			}/measurements`
		)
			.then((res) => {
				if (!res.ok) {
					throw new Error(res.statusText);
				}
				
				return res.json();
			})
			.then((data) => {
				console.log(data);
				setMeasurements({...data, height: heightInput*1000});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const saveModel = async (name: string) => {
		var result = {
			generatedId: id,
			name: name,
			createdAt: new Date().toLocaleString().replace(/(.*)\D\d+/, '$1'),
			objectModel: objectModel.obj,
			measurements: measurements
		};
		const newPhotos = [result, ...photos];
		Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) }).then(() => {
			console.log("SUCCESS")
			setPhotos(newPhotos as UserPhoto[]);
			history.push("/home")
		}).catch(async res => {
			console.log(res)
			if (res.message.includes("The quota has been exceeded")) {
				console.log(photos)
				console.log("localStorage is full")
				setIsOpen(true)
				//setLocalStorageIsFull(true)
			}
		})
	}

	useEffect(() => {
		// if new taskId, setIsfetched to false
		setIsFetched(false)
	}, [id])

	useEffect(() => {
		const getStorage = async () => {
			const { value } = await Storage.get({ key: PHOTO_STORAGE });
			const photosInStorage = (value ? JSON.parse(value) : []) as UserPhoto[];
			setPhotos(photosInStorage)
		}
		getStorage()
	}, [localStorageIsFull])

	useEffect(() => {
		if (!isFetched) {
			const interval = setInterval(() => {
				fetchModel();
				fetchMeasurements();
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [isFetched]);

	if (!isFetched) {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar></IonToolbar>
					<IonToolbar>
						<IonTitle className="titleToolbar">Loading Results</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="loadingIconText">
						<div className="loadingIcon">
							<IonSpinner color="tertiary" name="lines-sharp"></IonSpinner>
						</div>
						<br></br>
						<br></br>
						<IonText className="loadingText" color="tertiary">
							<h1>Please wait...</h1>
						</IonText>
					</div>
				</IonContent>
			</IonPage>
		);
	} else {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar></IonToolbar>
					<IonToolbar>
						<IonTitle className="titleToolbar">Results</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonGrid className="resultContent">
						<IonRow>
							<IonCol size="8">
								<IonCard className="resultPhoto" color="medium">
									<Canvas
										camera={{
											fov: 45,
											aspect: window.innerWidth / window.innerHeight,
											zoom: 1.3,
											near: 1,
											far: 1100,
										}}>
										<color attach="background" args={["grey"]} />
										<Suspense fallback={null}>
											<ThreeDModel
												id={objectModel.id}
												createdAt={objectModel.createdAt}
												obj={objectModel.obj}
												visibleEffects={objectModel.visibleEffects}
												measurements={objectModel.measurements}
											/>
										</Suspense>
									</Canvas>
								</IonCard>
							</IonCol>

							<IonCol size="4">
								<IonCard className="resultMeasurements" color="tertiary">
									<IonGrid className="measureBtns">
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 1) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualHeight}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={heightIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Height
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(measurements.height / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 2) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualHead}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={headIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Head
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(measurements.head / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 3) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualNeck}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={neckIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Neck
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(measurements.neck / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 4) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualChest}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={chestIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Chest
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(measurements.chest / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 5) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualArms}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={outerArmIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Arm
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(measurements.arm_outer / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 6) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualWaist}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={waistIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Waist
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(measurements.waist / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 7) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualButtocks}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={buttocksIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="3" className="bodyPartTitle">
															Buttocks
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(measurements.buttocks / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 8) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualLegs}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={innerLegIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Leg
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(measurements.leg_inner / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonCard>
							</IonCol>
						</IonRow>
						<div className="resultBtn">
							<NameModal saveModel={saveModel}/>
							<IonButton color="tertiary" shape="round" routerLink="/home">
								Close without saving
							</IonButton>
							<IonAlert
								isOpen={isOpen}
								header="Storage is full"
								subHeader="Error"
								message="The storage is full. Please delete an old measurement before saving the new one."
								buttons={['OK']}
								onDidDismiss={() => { setIsOpen(false); setLocalStorageIsFull(true) }}
							></IonAlert>

							<IonModal isOpen={localStorageIsFull}>
								<IonHeader>
									<IonToolbar>
										<IonTitle>Delete a measurement</IonTitle>
										<IonButtons slot="end">
											<IonButton onClick={() => setLocalStorageIsFull(false)}>Close</IonButton>
										</IonButtons>
									</IonToolbar>
								</IonHeader>
								<Measurements />
							</IonModal>
						</div>
					</IonGrid>
				</IonContent>
			</IonPage >
		);
	}
};

export default ResultPage;
