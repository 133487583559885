import * as THREE from "three";
import React, { useEffect, useRef, useState, } from "react";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { useLoader, useThree } from "@react-three/fiber";
import { Mesh, MeshPhongMaterial } from "three";
import { OrbitControls } from "@react-three/drei";
import { ObjectModel } from "../common/types";


const ThreeDModel: React.FC<ObjectModel> = (objectModel) => {
	const ref = useRef<Mesh>(null!);
	const [visual, setVisual] = useState<THREE.Group>(new THREE.Group());

	useEffect(() => {
		//console.log("data:model/obj;base64," + objectModel.obj);
	}, [objectModel.obj]);

	// model/obj or image/png; both works, probably others as well
	const obj = useLoader(OBJLoader, "data:model/obj;base64," + objectModel.obj);

	const scene = new THREE.Scene();

	const camera = useThree((state) => state.camera);
	camera.traverse((child) => {
		if (child instanceof THREE.PointLight) {
			camera.remove(child);
		}
	})
	scene.add(camera);

	obj.rotation.x = Math.PI;
	obj.rotation.y = Math.PI;

	let box = new THREE.Box3().setFromObject(obj);
	const size = box.getSize(new THREE.Vector3()).length();

	const material = new MeshPhongMaterial({
		color: 0xf3a2b0,
		wireframe: false,
		flatShading: false,
	});

	visual.clear();
	if (obj) {
		obj.remove(visual);
		obj.traverse((child) => {
			if (child instanceof Mesh) {
				child.material = material;
			}
		});
		//obj.position.set(0, 0, 0);
		obj.add(visual);
		scene.add(obj);
	}

	const light = new THREE.PointLight(0xffffff, 0.35);
	camera.add(light);

	const color = "#5260ff"; // FIXME: Color becomes brighter than specified
	const ringmat = new THREE.MeshBasicMaterial({
		color: color,
		wireframe: false,
		side: THREE.DoubleSide,
	});
	const ringwidth = 0.02;

	//arrow visual
	const dir = new THREE.Vector3(0, +1, 0);
	const origin = new THREE.Vector3(0.6, 0, 0);
	const arrow1 = new THREE.ArrowHelper(dir, origin, 1, color);

	const dir2 = new THREE.Vector3(0, -1, 0);
	const origin2 = new THREE.Vector3(0.6, 0, 0);
	const arrow2 = new THREE.ArrowHelper(dir2, origin2, 1, color);

	//double arrow visual
	const doubleArrow = new THREE.Group();
	doubleArrow.add(arrow1, arrow2);

	//Height visual
	const visualHeightRight = doubleArrow.clone();
	visualHeightRight.position.set(-1.15, -0.1, -0.1);
	visualHeightRight.rotation.x = Math.PI / 1;
	visualHeightRight.setRotationFromAxisAngle(
		new THREE.Vector3(1, 0, 0),
		Math.PI / 1.1
	);
	visualHeightRight.scale.set(1.1, 0.9, 1.1);

	const visualHeightLeft = doubleArrow.clone();
	visualHeightLeft.position.set(-0.15, -0.1, -0.1);
	visualHeightLeft.rotation.x = Math.PI / 1;
	visualHeightLeft.setRotationFromAxisAngle(
		new THREE.Vector3(1, 0, 0),
		Math.PI / 1.1
	);
	visualHeightLeft.scale.set(1.1, 0.9, 1.1);

	const visualHeightRL = new THREE.Group();
	visualHeightRL.add(visualHeightRight, visualHeightLeft);

	//Head visual
	const geometrytest1 = new THREE.RingGeometry(0.14, 0.14+ringwidth, 50);
	const visualHead = new THREE.Mesh(geometrytest1, ringmat);
	visualHead.position.set(0, -(size / 2) + 0.15, +0.2);
	visualHead.rotation.x = Math.PI / 2;

	//Neck visual
	const geometrytest3 = new THREE.RingGeometry(0.12, 0.12+ringwidth, 50);
	const visualNeck = new THREE.Mesh(geometrytest3, ringmat);
	visualNeck.position.set(-0.005, -(size / 2) + 0.3, +0.15);
	visualNeck.rotation.x = Math.PI / 3;

	//Chest visual
	const geometrytestChest = new THREE.RingGeometry(0.3, 0.3+ringwidth, 50);
	const visualChest = new THREE.Mesh(geometrytestChest, ringmat);
	visualChest.position.set(0, -(size / 2) + 0.5, +0.1);
	visualChest.rotation.x = Math.PI / 2.3;

	//Arm visual
	const visualArmRight = doubleArrow.clone();
	visualArmRight.position.set(-0.15, -0.1, -0.0);
	visualArmRight.rotation.x = Math.PI / 1;
	visualArmRight.setRotationFromAxisAngle(
		new THREE.Vector3(1, -0.2, 0),
		Math.PI / 1.1
	);
	visualArmRight.scale.set(1, 0.3, 0.8);

	const visualArmLeft = doubleArrow.clone();
	visualArmLeft.position.set(-0.95, -0.55, +0.05);
	visualArmLeft.rotation.x = Math.PI / 1;
	visualArmLeft.setRotationFromAxisAngle(
		new THREE.Vector3(1, +0.2, 0),
		Math.PI / 1.1
	);
	visualArmLeft.scale.set(1, 0.3, 0.8);

	const visualArmsRL = new THREE.Group();
	visualArmsRL.add(visualArmRight, visualArmLeft);

	//Waist visual
	const geometrytestWaist = new THREE.RingGeometry(0.18, 0.18+ringwidth, 50);
	const visualWaist = new THREE.Mesh(geometrytestWaist, ringmat);
	visualWaist.position.set(0, -(size / 2) + 0.7, +0.05);
	visualWaist.rotation.x = Math.PI / 2.4;

	//Buttocks visual
	const geometrytestButtocks = new THREE.RingGeometry(0.25, 0.25+ringwidth, 50);
	const visualButtocks = new THREE.Mesh(geometrytestButtocks, ringmat);
	visualButtocks.position.set(0, -(size / 2) + 0.85, 0);
	visualButtocks.rotation.x = Math.PI / 2.4;

	//Leg visual
	const visualLegRight = doubleArrow.clone();
	visualLegRight.position.set(-0.34, +0.3, -0.15);
	visualLegRight.rotation.x = Math.PI / 1;
	visualLegRight.setRotationFromAxisAngle(
		new THREE.Vector3(1, 0, 0),
		Math.PI / 1.1
	);
	visualLegRight.scale.set(1, 0.4, 0.8);

	const visualLegLeft = doubleArrow.clone();
	visualLegLeft.position.set(-0.86, +0.3, -0.15);
	visualLegLeft.rotation.x = Math.PI / 1;
	visualLegLeft.setRotationFromAxisAngle(
		new THREE.Vector3(1, 0, 0),
		Math.PI / 1.1
	);
	visualLegLeft.scale.set(1, 0.4, 0.8);

	const visualLegsRL = new THREE.Group();
	visualLegsRL.add(visualLegRight, visualLegLeft);
	//end of creation of individual measurement visuals to be added to scene



	//start of adding individual measurement visuals to scene
	let measurementVisual: number[] = objectModel.visibleEffects;
	let measurementVisualActions: number[] = measurementVisual || [];
	//if (measurementVisualActions === undefined) {
	//  console.log("for some reason measurementVisualActions[] number value is undefined upon page load but everything works");
	//} else
	if (measurementVisualActions.includes(0)) {
		// Do nothing
	} else if (measurementVisualActions.includes(1)) {
		visual.add(visualHeightRL);
	} else if (measurementVisualActions.includes(2)) {
		visual.add(visualHead);
	} else if (measurementVisualActions.includes(3)) {
		visual.add(visualNeck);
	} else if (measurementVisualActions.includes(4)) {
		visual.add(visualChest);
	} else if (measurementVisualActions.includes(5)) {
		visual.add(visualArmsRL);
	} else if (measurementVisualActions.includes(6)) {
		visual.add(visualWaist);
	} else if (measurementVisualActions.includes(7)) {
		visual.add(visualButtocks);
	} else if (measurementVisualActions.includes(8)) {
		visual.add(visualLegsRL);
	}
	//end of adding individual measurement visuals to scene

	return (
		//returning 3D model object + scene (on canvas)
		<>
			<primitive object={scene} ref={ref} />
			<OrbitControls />
		</>
	);
};

export default ThreeDModel;
