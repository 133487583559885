import { IonButton, IonContent, IonIcon } from '@ionic/react'
import { useEffect } from 'react'
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import { Storage } from '@capacitor/storage';
import { PHOTO_STORAGE, UserPhoto } from '../common/types';
import { trashOutline } from 'ionicons/icons';

function Measurements() {
	const { photos, setPhotos } = usePhotoGallery();

	const deleteMeasurement = async (index: number) => {
		console.log(index)
		const { value } = await Storage.get({ key: PHOTO_STORAGE });
		const photosInStorage = (value ? JSON.parse(value) : []) as UserPhoto[];
		photosInStorage.splice(index, 1)
		Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(photosInStorage) })
		setPhotos(photosInStorage)
	}

	useEffect(() => {
	}, [photos]);

	return (
		<>
			<IonContent fullscreen>
				{photos.sort((a, b) => {
					return Date.parse(b.createdAt) - Date.parse(a.createdAt)
				}).map((photo, index) => {
					return (
						<div key={index}>
							<IonButton
								className="measurement"
								onClick={() => deleteMeasurement(index)}
								expand="block"
								size="large"
								shape="round"
								color="light"
							>
								{!photo.name ? (<></>) : (<>{photo.name}</>)} <br className="row-separation" /> {photo.createdAt}
								<IonIcon slot="end" color="danger" icon={trashOutline}></IonIcon>
							</IonButton>
						</div>
					);
				})}
			</IonContent>
		</>
	)
}

export default Measurements