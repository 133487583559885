import { IonAlert, IonButton, IonCard, IonCardContent } from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import '../theme/Home.css';
import { Context } from '../MyContext';
import ChooseCalibration from './ChooseCalibration';
import { PIPEDB_URL } from '../common/pipedb-url';
import { useAuth0 } from '@auth0/auth0-react';
import Gdpr from './Gdpr';

interface ContainerProps { }

const MainScreen: React.FC<ContainerProps> = () => {
	const { isAuthenticated } = useAuth0()

	const { taskId, setTaskId } = useContext(Context)

	const generateTask = async () => {
		let taskReq = await fetch(`${PIPEDB_URL}/task`, {
			method: 'POST',
			body: JSON.stringify({ })
		});

		let taskText = await taskReq.text();
		let task = await JSON.parse(taskText);
		setTaskId(task["ID"]);
	}

	return (
		<div className="container">
			<div>
				{isAuthenticated || window.location.hostname.includes('192') ? <>
					<IonButton
						id="open-modal"
						onClick={() => { generateTask() }}>
						New Measurement
					</IonButton>
					<ChooseCalibration />

					<IonButton routerLink="/measurements">My Measurements</IonButton>
				</> : <>
					<IonCard style={{width:'50%', left:'25%'}}>
						<IonCardContent>
							Please log in to use application!
							

						</IonCardContent>
					</IonCard>
				</>}
			</div>

		</div>
	);
};

export default MainScreen;
