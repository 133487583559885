import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonContent,
	IonHeader,
	IonIcon,
	IonImg,
	IonPage,
	IonSpinner,
	IonText,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import {
	homeOutline,
	informationCircleOutline,
} from "ionicons/icons";
import { useContext, useEffect, useMemo, useState } from "react";
import { orientationTransform } from '../common/orientation-transform';
import { delay } from '../common/delay';
import { PIPEDB_URL } from '../common/pipedb-url';

import { Context } from "../MyContext";

import "../theme/Utilities.css";
import { useParams } from "react-router";
import { ImageResource, OrientResource } from "../common/types";
interface ObjectMaskProps {
	id: number;
	createdAt: string;
	pixels: string;
}

const RightMaskPage: React.FC = () => {
	const { id } = useParams<{ id: string }>()
	const { sideImgDone, sideMaskDone, setSideMaskDone } = useContext(Context);
	const [image, setImage] = useState<ImageResource>();
	const [objectMask, setObjectMask] = useState<ImageResource>();
	const [orientation, setOrientation] = useState<OrientResource>();

	const which = "ready";

	const fetchImage = async () => {
		while (true) {
			try {
				console.log("ID:", id);
				let response = await fetch(`${PIPEDB_URL}/task/${id}/right_img`);
				setImage(await response.json());
				if (response.status == 200) return;
			} catch (error: any) {
				console.error(error);
			}
			await delay(100);
		}
	};

	const fetchMask = async () => {
		while (true) {
			try {
				let response = await fetch(`${PIPEDB_URL}/task/${id}/right_mask`);
				setObjectMask(await response.json());
				if (response.status == 200) return;
			} catch (error: any) {
				console.error(error);
			}
			await delay(100);
		}
	};

	const fetchOrientation = async () => {
		while (true) {
			try {
				let response = await fetch(`${PIPEDB_URL}/task/${id}/right_orient`);
				setOrientation(await response.json());
				if (response.status == 200) return;
			} catch (error: any) {
				console.error(error);
			}
			await delay(100);
		}
	};

	const optimizeImages = async () => {
		let response = await fetch(
			`${PIPEDB_URL}/task/${id}/${which}`,
			{
				method: "POST",
				body: JSON.stringify({ createdBy: "app-alpha1" }),
			}
		);
		console.log(id, which, await response.json());
	};

	const maskTransform = useMemo(() => {
		return orientationTransform(orientation?.orient);
	}, [orientation]);

	useEffect(() => {
		const doAsync = async() => {
			await Promise.all([fetchImage(), fetchMask(), fetchOrientation()]);
			setSideMaskDone(true);
		};
		if (sideImgDone && !sideMaskDone) doAsync();
	}, [sideImgDone, sideMaskDone, id]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonTitle className="infoBtn">
							<IonButton routerLink="/InstructionsOnSwipe">
								<IonIcon slot="icon-only" icon={informationCircleOutline} />
							</IonButton>
						</IonTitle>
					</IonButtons>
					<IonButtons slot="end">
						<IonButton routerLink="/home" class="homeBtn">
							<IonIcon slot="icon-only" icon={homeOutline}></IonIcon>
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonToolbar>
					<IonTitle className="titleToolbar">Photo preview</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				{!sideMaskDone ? (
					<div className="loadingIconText">
						<div className="loadingIcon">
							<IonSpinner color="tertiary" name="lines-sharp"></IonSpinner>
						</div>
						<br></br>
						<br></br>
						<IonText className="loadingText" color="tertiary">
							<h1>Please wait...</h1>
						</IonText>
					</div>
				) : (
					<><div className="previewImageContainer">
						<IonImg
							src={`data:image/png;base64,${image?.pixels}`}
							className="previewImage"
							style={{ width: 420, height: 420 }} />
						<div className="previewMaskOverlay"><IonImg
							src={`data:image/png;base64,${objectMask?.pixels}`}
							className="previewMask"
							style={{ transform: `${maskTransform}`, width: 420, height: 420 }} /></div>
					</div><IonCard>
							<IonCardContent>
								Does the silhouette match the image and are you happy with the
								picture? If not, please retake the picture.
							</IonCardContent>
						</IonCard><div className="buttons">
							<IonButton routerLink={`/task/${id}/right_img`}>
								Retake photo
							</IonButton>
							<IonButton onClick={() => optimizeImages()} routerLink={`/task/${id}/result`}>
								See results
							</IonButton>
						</div></>
				)}
			</IonContent>
		</IonPage>
	);
};

export default RightMaskPage;
