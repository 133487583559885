import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react"
import { informationCircleOutline } from "ionicons/icons"
import LoginButton from "./LoginButton"
import Gdpr from "./Gdpr"
import { useState } from "react"

const Toolbar: React.FC = () => {
  const [showAlert, setShowAlert] = useState(false);
    return (
<IonHeader>
<Gdpr isOpen={showAlert} ></Gdpr>
<IonToolbar>
  <IonButtons slot="primary">
  <IonButton onClick={() => setShowAlert(true)}>GDPR</IonButton>

    <IonTitle>
      <IonButton routerLink="/InstructionsOnSwipe">
        <IonIcon slot="icon-only" icon={informationCircleOutline} />
      </IonButton>
    </IonTitle>
    </IonButtons>
    <IonButtons slot="start">
        <LoginButton></LoginButton>
    </IonButtons>
</IonToolbar>
</IonHeader>
    )
}
export default Toolbar;
