export interface ImageResource {
	id: string;
	createdAt: string;
	pixels: string;
}

export interface OrientResource {
	id: string;
	createdAt: string;
	orient: number;
}

export interface MeasurementResource {
	id: string;
	createdAt: string;
	height: number;
	head: number;
	neck: number;
	chest: number;
	waist: number;
	buttocks: number;
	leg_inner: number;
	arm_outer: number;
}

export interface UserPhoto {
	generatedId: string;
	name: string;
	createdAt: string;
	objectModel: string;
	measurements: MeasurementResource;
}

export const PHOTO_STORAGE = 'photos';

export interface ObjectModel {
	id: string;
	createdAt: string;
	obj: string;
	visibleEffects: number[];
	measurements: MeasurementResource
}

export interface Env {
	REACT_APP_RUN_MODE: string | undefined;
}
export interface UserStoragePrefs {
	orig_images?: boolean;
	model_inputs?: boolean;
	results?: boolean;
	truth_data?: boolean;
}
declare global {
	interface Window {
		_env_: Env;
	}
}

