import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import FrontInstructionsPage from './pages/FrontInstructionsPage';
import MyContextProvider from './MyContext';
import SideInstructionsPage from './pages/SideInstructionsPage';
import ResultPage from './pages/ResultPage';
import MeasurementsPage from './pages/MeasurementsPage';
import MeasurementsResultsPage from './pages/MeasurementsResultsPage';
import FrontMaskPage from './pages/FrontMaskPage';
import SideMaskPage from './pages/SideMaskPage';
import InstructionsOnSwipe from './pages/InstructionsOnSwipe';

//import Debug from './pages/Debug';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <MyContextProvider>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/home">
            <Home />
          </Route>
          {/* <Route exact path="/debug" component={Debug} /> */}
          <Route
            exact
            path="/task/:id/results"
            component={MeasurementsResultsPage}
          />
          <Route exact path="/measurements" component={MeasurementsPage} />
          <Route
            exact
            path="/task/:id/front_img"
            component={FrontInstructionsPage}
          />
          <Route exact path="/task/:id/front_mask" component={FrontMaskPage} />
          <Route
            exact
            path="/task/:id/right_img"
            component={SideInstructionsPage}
          />
          <Route exact path="/task/:id/right_mask" component={SideMaskPage} />
          <Route exact path="/task/:id/result" component={ResultPage} />
          <Route
            exact
            path="/InstructionsOnSwipe"
            component={InstructionsOnSwipe}
          />
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </MyContextProvider>
  </IonApp>
);

export default App;
