import { IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonItem, IonLabel, IonInput, IonList, IonRadio, IonRadioGroup, RadioGroupCustomEvent } from '@ionic/react'
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../MyContext';
import calibration from '../assets/calibration.json'
import { PIPEDB_URL } from '../common/pipedb-url';
import { Storage } from "@capacitor/storage";
import { UserStoragePrefs } from '../common/types';

interface ModelProps {
	name: string;
	model: string;
	scale: number[];
	center: number[];
	dist_coeffs: number[];
}

type Gender = "neutral" | "female" | "male";

function ChooseCalibration(props: any) {

	const { taskId, setTaskId, setFrontMaskDone, setSideMaskDone } = useContext(Context)
	const [model, setModel] = useState<ModelProps>(calibration[0] as ModelProps)
	const [gender, setGender] = useState<Gender>("neutral")
	const [height, setHeight] = useState<number>(170)
	const [message, setMessage] = useState('This modal example uses triggers to automatically open a modal when the button is clicked.');
	const modal = useRef<HTMLIonModalElement>(null);
	const input = useRef<HTMLIonInputElement>(null);
    const [consent, setConsent] = useState<UserStoragePrefs>({});
	useEffect(()=>{
        async function getConsent(){

        const stored = await Storage.get({key:'consent'});
        if(stored.value){
            setConsent(JSON.parse(stored.value));
        }else{
            setConsent({model_inputs:false,orig_images:false,results:false,truth_data:false});
        }
    }
    getConsent();
    },[]);


	function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
		if (ev.detail.role === 'confirm') {
			setMessage(`Hello, ${ev.detail.data}!`);
		}
	}
	async function getStoragePrefs(randomId: number) {
		const stored = await Storage.get({ key: 'consent' });
		if (stored.value) {
			return {...{results:false,orig_images:false,model_inputs:false, truth_data:false}, ...JSON.parse(stored.value)};
		} else {
			return { model_inputs: false, orig_images: false, results: false, truth_data:false};
		}
	};

	



	const addCalibrations = async (randomId: number, model: ModelProps) => {
		console.log(model?.scale)
		console.log("Height:", height)

		try {
			await fetch(`${PIPEDB_URL}/task/${randomId}/front_proj`, {
				method: 'POST',
				body: JSON.stringify({
					scale: [2.0, 1.5], // model?.scale,
					center: [0,0] //model?.center
				})
			}
			)
			const storagePrefs = await getStoragePrefs(randomId);
			console.log('prefs', storagePrefs);
			await fetch(`${PIPEDB_URL}/task/${randomId}/user_storage_prefs`, {
				method: 'POST',
				body: JSON.stringify(storagePrefs)
			})

			await fetch(`${PIPEDB_URL}/task/${randomId}/right_proj`, {
				method: 'POST',
				body: JSON.stringify({
					scale:[2.0, 1.5],// model?.scale,
					center:[0,0]// model?.center
				})
			})

			await fetch(`${PIPEDB_URL}/task/${randomId}/height_input`, {
				method: 'POST',
				body: JSON.stringify({
					value: Number(height / 100)
				})
			})

			await fetch(`${PIPEDB_URL}/task/${randomId}/gender`, {
				method: 'POST',
				body: JSON.stringify({
					value: gender
				})
			})
		} catch (error) {
			console.log(error)
		}
		modal.current?.dismiss(input.current?.value, 'confirm');
	}

	const handleModelChange = (e: React.MouseEvent<HTMLIonRadioElement, MouseEvent>) => {
		setModel(e.currentTarget.value)
	}

	const handleGenderChange = (e: RadioGroupCustomEvent<Gender>) => {
		setGender(e.detail.value);
	}

	useEffect(() => {
		console.log(model)
	}, [model])



	return (
		<IonModal ref={modal} trigger="open-modal" onWillDismiss={(ev) => onWillDismiss(ev)}>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton onClick={() => { modal.current?.dismiss(); }}>Cancel</IonButton>
					</IonButtons>
					<IonTitle>New Measurement</IonTitle>
					<IonButtons slot="end">
						<IonButton strong={true} onClick={() => { addCalibrations(taskId, model) }} routerLink={`task/${taskId}/front_img`}>
							Confirm
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			{/* <IonList>
				<IonRadioGroup allowEmptySelection={true}>
					{calibration.map(model => {
						return (
							<IonItem key={model.name}>
								<IonLabel>{model.name}</IonLabel>
								<IonRadio slot="end" value={model} onClick={(e) => handleModelChange(e)}></IonRadio>
							</IonItem>
						)
					})}
				</IonRadioGroup>
			</IonList> */}
			<IonList>
				<IonItem>
					<IonLabel>Which is most similar to your body type?</IonLabel>
				</IonItem>
				<IonRadioGroup value={gender} onIonChange={handleGenderChange}>
					<IonItem><IonLabel>Female</IonLabel><IonRadio slot="start" value="female"></IonRadio></IonItem>
					<IonItem><IonLabel>Neutral</IonLabel><IonRadio slot="start" value="neutral"></IonRadio></IonItem>
					<IonItem><IonLabel>Male</IonLabel><IonRadio slot="start" value="male"></IonRadio></IonItem>
				</IonRadioGroup>
				<IonItem>
					<IonLabel style={{maxWidth: "none", paddingRight: "2em"}}>How tall are you in centimeters?</IonLabel>
					<IonInput id="height_input" type="number" value={height} min={0} max={300} onIonInput={(e) => setHeight(e.target.value as number)}></IonInput>
				</IonItem>
			</IonList>
		</IonModal>
	)
}

export default ChooseCalibration
