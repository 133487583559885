import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonPage,
	IonRow,
	IonSpinner,
	IonText,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { Canvas } from "@react-three/fiber";
import {
	arrowBackOutline,
	homeOutline,
	informationCircleOutline,
} from "ionicons/icons";
import { Suspense, useEffect, useState } from "react";
import ThreeDModel from "../components/3DModel";
import "../theme/Results.css";

import "../theme/Utilities.css";
import heightIcon from "../assets/Icons-Images-Video/heightIcon.png";
import headIcon from "../assets/Icons-Images-Video/headIcon.png";
import neckIcon from "../assets/Icons-Images-Video/neckIcon.png";
import chestIcon from "../assets/Icons-Images-Video/chestIcon.png";
import waistIcon from "../assets/Icons-Images-Video/waistIcon.png";
import buttocksIcon from "../assets/Icons-Images-Video/buttocksIcon.png";
import innerLegIcon from "../assets/Icons-Images-Video/innerLegIcon.png";
import outerArmIcon from "../assets/Icons-Images-Video/outerArmIcon.png";
import { usePhotoGallery } from "../hooks/usePhotoGallery";
import { useParams } from "react-router";
import { MeasurementResource, ObjectModel } from "../common/types";


interface measurementsProps {
	id: number;
	createdAt: string;
	height: number;
	head: number;
	neck: number;
	chest: number;
	waist: number;
	buttocks: number;
	leg_inner: number;
	arm_outer: number;
}

const ResultPage: React.FC = () => {
	const { id } = useParams<{ id: string }>()
	const { photos } = usePhotoGallery()
	const [isFetched, setIsFetched] = useState(false);
	const [intervalTime, setIntervalTime] = useState<number>(1000)
	const [objectModel, setObjectModel] = useState<ObjectModel>({
		id: "",
		createdAt: "",
		obj: "",
		visibleEffects: [0],
		measurements: {
			id: "",
			createdAt: "",
			height: 0,
			head: 0,
			neck: 0,
			chest: 0,
			waist: 0,
			buttocks: 0,
			leg_inner: 0,
			arm_outer: 0
		}
	});

	function showHideVisualHeight() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [1] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 1) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});

		}
	}

	function showHideVisualHead() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [2] },
		});

		if (
			objectModel.visibleEffects &&
			objectModel.visibleEffects[0] === 2
		) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});

		}
	}

	function showHideVisualNeck() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [3] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 3) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});

		}
	}

	function showHideVisualChest() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [4] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 4) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});

		}
	}

	function showHideVisualArms() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [5] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 5) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});

		}
	}

	function showHideVisualWaist() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [6] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 6) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});

		}
	}

	function showHideVisualButtocks() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [7] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 7) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});

		}
	}

	function showHideVisualLegs() {
		setObjectModel({
			...objectModel,
			...{ visibleEffects: [8] },
		});

		if (objectModel.visibleEffects && objectModel.visibleEffects[0] === 8) {
			setObjectModel({
				...objectModel,
				...{ visibleEffects: [0] },
			});

		}
	}

	const fetchModel = async () => {
		var model = photos.find(object => object.generatedId === id)
		var objModel = {
			id: id,
			createdAt: model?.createdAt as string,
			obj: model?.objectModel as string,
			measurements: model?.measurements as MeasurementResource
		} as ObjectModel
		if (objModel.obj === undefined) {
			objModel.obj = ""
		}
		setObjectModel({ ...objectModel, ...objModel })
	};

	useEffect(() => {
		if (objectModel.obj !== "") {
			setIsFetched(true)
		}
	}, [isFetched, objectModel])

	useEffect(() => {
		fetchModel()
	}, [isFetched, photos]);

	if (!isFetched) {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/">
								<IonIcon slot="icon-only" icon={arrowBackOutline} />
							</IonBackButton>
						</IonButtons>

						<IonButtons>
							<IonTitle className="infoBtn">
								<IonButton routerLink="/InstructionsOnSwipe">
									<IonIcon slot="icon-only" icon={informationCircleOutline} />
								</IonButton>
							</IonTitle>
						</IonButtons>

						<IonButtons slot="end">
							<IonButton routerLink="/home" class="homeBtn">
								<IonIcon slot="icon-only" icon={homeOutline}></IonIcon>
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<IonToolbar>
						<IonTitle className="titleToolbar">Results</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="loadingIconText">
						<div className="loadingIcon">
							<IonSpinner color="tertiary" name="lines-sharp"></IonSpinner>
						</div>
						<br></br>
						<br></br>
						<IonText className="loadingText" color="tertiary">
							<h1>Please wait...</h1>
						</IonText>
					</div>
				</IonContent>
			</IonPage>
		);
	} else {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/">
								<IonIcon slot="icon-only" icon={arrowBackOutline} />
							</IonBackButton>
						</IonButtons>

						<IonButtons>
							<IonTitle className="infoBtn">
								<IonButton routerLink="/InstructionsOnSwipe">
									<IonIcon slot="icon-only" icon={informationCircleOutline} />
								</IonButton>
							</IonTitle>
						</IonButtons>

						<IonButtons slot="end">
							<IonButton routerLink="/home" class="homeBtn">
								<IonIcon slot="icon-only" icon={homeOutline}></IonIcon>
							</IonButton>
						</IonButtons>
					</IonToolbar>

					<IonToolbar>
						<IonTitle className="titleToolbar">Results</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonGrid className="contentGrid">
						<IonRow>
							<IonCol size="8">
								<IonCard className="resultPhoto" color="medium">
									<Canvas
										camera={{
											fov: 45,
											aspect: window.innerWidth / window.innerHeight,
											zoom: 1.3,
											near: 1,
											far: 1100,
										}}>
										<color attach="background" args={["grey"]} />
										<Suspense fallback={null}>
											<ThreeDModel
												id={objectModel.id}
												createdAt={objectModel.createdAt}
												obj={objectModel.obj}
												visibleEffects={objectModel.visibleEffects}
												measurements={objectModel.measurements}
											/>
										</Suspense>
									</Canvas>
								</IonCard>
							</IonCol>

							<IonCol size="4">
								<IonCard className="resultMeasurements" color="tertiary">
									<IonGrid className="measureBtns">
										<IonRow>
											<IonCol className="BtnList">
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 1) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualHeight}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={heightIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Height
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(objectModel.measurements.height / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 2) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualHead}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={headIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Head
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(objectModel.measurements.head / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 3) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualNeck}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={neckIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Neck
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(objectModel.measurements.neck / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 4) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualChest}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={chestIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Chest
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(objectModel.measurements.chest / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 5) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualArms}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={outerArmIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Arm
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(objectModel.measurements.arm_outer / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 6) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualWaist}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={waistIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Waist
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(objectModel.measurements.waist / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 7) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualButtocks}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={buttocksIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="3" className="bodyPartTitle">
															Buttocks
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(objectModel.measurements.buttocks / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonButton
													fill="outline"
													color="dark"
													className={(objectModel.visibleEffects && objectModel.visibleEffects[0] == 8) ? "selectedMeasurement" : undefined}
													onClick={showHideVisualLegs}>
													<div className="iconSpace">
														<IonCol>
															<img
																src={innerLegIcon}
																alt=""
																className="bodyIcons"
															/>{" "}
														</IonCol>
													</div>
													<IonRow>
														<IonCol offset="4" className="bodyPartTitle">
															Leg
														</IonCol>
														<IonCol offset="2" className="measureUnit">
															{Math.round(objectModel.measurements.leg_inner / 10)} cm
														</IonCol>
													</IonRow>
												</IonButton>
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonCard>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonPage>
		);
	}
};

export default ResultPage;
