import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonPage,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import {
	homeOutline,
	informationCircleOutline,
} from "ionicons/icons";
import { useContext } from "react";
import image from "../assets/Instructions/SidePoseInstructions.png";
import { usePhotoGallery } from "../hooks/usePhotoGallery";
import { Context } from "../MyContext";
import "../theme/Instructions.css";
import "../theme/Utilities.css";
import { useParams } from "react-router";

const FrontPosePage: React.FC = () => {
	const { id } = useParams<{ id: string }>()
	const { takePhoto } = usePhotoGallery();
	const which = "right_img";

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonTitle className="infoBtn">
							<IonButton routerLink="/InstructionsOnSwipe">
								<IonIcon slot="icon-only" icon={informationCircleOutline} />
							</IonButton>
						</IonTitle>
					</IonButtons>

					<IonButtons slot="end">
						<IonButton routerLink="/home" class="homeBtn">
							<IonIcon slot="icon-only" icon={homeOutline}></IonIcon>
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonToolbar>
					<IonTitle className="titleToolbar">Side Pose</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<div className="image">
					<img src={image} alt="" />
				</div>

				<div className="buttonsUnderInstructions">
					<IonButton
						shape="round"
						color="success"
						onClick={() => takePhoto(id, which)}>
						open camera
					</IonButton>
				</div>

			</IonContent>
		</IonPage>
	);
};

export default FrontPosePage;
